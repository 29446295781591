import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { MDXRenderer } from 'gatsby-plugin-mdx';

import * as styles from './styles.module.scss';

const About = () => {
  const { mdx } = useStaticQuery(
    graphql`
      query {
        mdx(slug: {eq: "about"}) {
          body
          frontmatter {
            title
          }
        }
      }
    `);

  return (
    <article className={styles.about}>
      <h1 style={{textAlign: "left"}}>about</h1>
      <MDXRenderer>
        {mdx.body}
      </MDXRenderer>
    </article>
  );
};

export default About;