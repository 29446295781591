import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import About from "../../components/About";

import * as styles from "./styles.module.scss";

const query = graphql`
  query {
    file(relativePath: { eq: "jesse-studio-stretch.png" }) {
      childImageSharp {
        desktop: gatsbyImageData(aspectRatio: 3.2, layout: FULL_WIDTH)
        tablet: gatsbyImageData(aspectRatio: 1.777777, layout: FULL_WIDTH)
        mobile: gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH)
      }
    }
  }
`;

const AboutPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout title="about">
      <GatsbyImage
        className={styles.heroImage}
        image={withArtDirection(getImage(data.file.childImageSharp.mobile), [
          {
            media: `(min-width: 768px)`,
            image: getImage(data.file.childImageSharp.tablet),
          },
          {
            media: `(min-width: 1024px)`,
            image: getImage(data.file.childImageSharp.desktop),
          },
        ])}
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
